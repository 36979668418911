@font-face {
  font-family: "OpenSansMedium";   /*Can be any text*/
  src: local("OpenSansMedium"),
    url("./fonts/OpenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansRegular";   /*Can be any text*/
  src: local("OpenSansRegular"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansBold";   /*Can be any text*/
  src: local("OpenSansBold"),
    url("./fonts/OpenSans-Bold.ttf") format("truetype");
}

.App {
  text-align: center;
}
.header-spacer { height: 100px; }
.App .main-content { width: 100%; height: 100%; padding: 20px 0; text-align: justify; }
.SomeContent { padding: 2000px 0;}
.home-container {
    .main-description { font-size: var(--text); line-height: var(--ltext); text-align: justify; display: flex; padding-bottom: 8px;
        .image-container { line-height: 0; width: 100%; max-width: 1200px; margin: 0 auto;
            img { width: 100%; max-width: 100%;}
        }
        .description { margin-top: auto; margin-bottom: auto; }

    }
    .description-container { border-top: 20px #4bab9e solid; border-bottom: 1px solid #a8a9ad; padding-bottom: var(--big);
        .container { 
            .title { padding-top: var(--small); text-align: center; font-size: var(--title); line-height: var(--ltitle); position: relative; 
                &:before { left: 50%; transform: translateX(-50%); bottom: -24px;  position: absolute; display: block; content: ""; background-color: #cda766; height: 2px; width: 250px;}
            }
            .description { padding: var(--medium) 8px 0; font-weight: 600; text-align: center; font-size: var(--text); line-height: var(--ltext); }
            .signature { padding-top: var(--medium); margin: 0 auto; text-align: center; font-size: var(--text); line-height: var(--ltext); font-weight: bold; width: 600px;
                img { width: 100%; }
            }
            @media screen and (max-width: 768px) { 
                .signature { width: 300px; }
            }
        }
    }

    .qna-container { background-color: #eef0f0; padding: var(--medium);
        @media screen and (max-width: 768px) { padding: var(--small) 0; }
    }

    .services-container { padding: var(--medium) 0 var(--big); 
        .container {
            .title { text-align: center; font-size: var(--textm); line-height: var(--textm); font-weight: 400; text-decoration: underline; }
            .services-list { display: flex; justify-content: space-between; padding-top: var(--medium);
                .service { width: 33%; 
                    a { 
                        .image-container { width: 100%; text-align: center;
                            img { height: 170px; }
                        }
                        .description { padding-top: var(--medium); text-align: center; margin: 0 auto;
                            .title { font-size: var(--stext); line-height: var(--lstext); text-decoration: unset; font-weight: 600; position: relative; display: inline-block;color: #000;
                                &:before { position: absolute; bottom: -4px; width: 0; height: 2px; background-color: #4bab9e; content: ""; transition: width 0.4s ease-in-out; }
                            }
                        }
                        &:hover {
                            .description {
                                .title { &:before { width: 100%; } }
                            }
                        }
                    }
                    
                }
            }
            @media screen and (max-width: 768px) { 
                .services-list { flex-wrap: wrap;
                    .service { width: 100%; padding-bottom: var(--medium);
                        a { 
                            .image-container { width: 100%; text-align: center;
                                img { height: 170px; }
                            }
                            .description { padding-top: var(--medium); text-align: center; margin: 0 auto;
                                .title { font-size: var(--stext); line-height: var(--lstext); text-decoration: unset; font-weight: 600; position: relative; display: inline-block;color: #000;
                                    &:before { position: absolute; bottom: -4px; width: 0; height: 2px; background-color: #4bab9e; content: ""; transition: width 0.4s ease-in-out; }
                                }
                            }
                            &:hover {
                                .description {
                                    .title { &:before { width: 100%; } }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .online-container { background-color: #eef0f0;
        .container { display: flex; position: relative; min-height: 200px; align-content: center; justify-content: center; flex-wrap: wrap;
            .image-container { position: absolute; left: 0; top: 50%; transform: translateY(-50%);
                img { max-height: 150px; }
            }
            .desc-container { display: flex; flex-direction: column; align-items: center; justify-content: center;
                .description { padding-bottom: var(--small); text-align: center; font-size: var(--stext); line-height: var(--ltext); font-weight: 600; }
                .button-container {  display: flex;
                    a {
                        &:first-child { margin-right: 8px; }
                    }
                }
            }
            @media screen and (max-width: 1580px) {
                .image-container { left: 16px; }
            }
            @media screen and (max-width: 768px) {
                flex-direction: column; padding: var(--medium) 0;
                .image-container { position: unset; transform: unset; margin: 0 auto;
                    img { max-height: 150px; }
                }
                .desc-container { padding-top: var(--small); }
            }
            @media screen and (max-width: 480px) {
                .desc-container {
                    .button-container { flex-direction: column;
                        a {
                            &:first-child { margin-right: 0; margin-bottom: 8px; }
                        }
                    } 
                }
            }
        }
    }
    .systemic-container {
        .container { display: flex; justify-content: space-between; padding: var(--medium) 0;
            .image-container { width: 40%; margin-right: 24px;
                img { max-width: 400px; height: 100%; transform: scaleX(-1); }
            }
            .desc-container {  width: calc( 60% - 24px); 
                .title-container { padding-top: var(--medium); text-align: left; margin: 0 auto;
                    .title { font-size: var(--title); line-height: var(--lbtitle); text-decoration: unset; font-weight: 700; position: relative; display: inline-block; }
                }
                .description { padding-top: var(--small); text-align: left; margin: 0 auto; font-size: var(--text); line-height: var(--ltext); font-weight: 600; }
                .button-container { padding-top: var(--small); text-align: right;}
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .image-container { width: 100%; margin: 0 auto; text-align: center;
                    img { max-width: 400px; height: 100%; transform: scaleX(-1); width: 100%; }
                }
                .desc-container { width: 100%; 
                    .title-container { margin: 0 auto;
                        .title { font-size: var(--title); line-height: var(--lbtitle); text-decoration: unset; font-weight: 700; position: relative; display: inline-block; }
                    }
                    .description { padding-top: var(--small); margin: 0 auto; font-size: var(--text); line-height: var(--ltext); font-weight: 600; }
                    .button-container { padding-top: var(--medium); }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .home-container {
        .main-description { flex-wrap: wrap;
            .image-container {  margin: 0 auto; margin-bottom: 24px;
                img { width: 100%; }
            }
        }
    }
}
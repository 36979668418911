@import '../../global/styling/generic.scss';
.isMobile {
    .header-container {
        .first {
            .mobile-menu { display: block; margin-right: 20px;
                > a { font-size: 25px; 
                    svg { font-size: 25px; margin-top: 25px; color: #fff;}
                }
                .mobile-menu-container { transition: 0.6s left;
                    &.mobile-hidden { opacity: 0;
                        .menu-container { left: -100%; }
                    }
                }
            }
            .header-menu-container { display: none; }
        }
    }
}
.headerIsScrolled {
    .header-container { top: -100%; }
    .header-sticky-container { top: 0; z-index: 10; }
}
.header-space { height: 100px; }
.header-container {   animation: slideInFromTop 0.5s ease-in-out; background-color: $bgColor; position: fixed; width: 100%; transition: 0.5s ease-in-out top; top:0; z-index: 10;
    .zero { position: relative; background-color: $green; display: none;
        .contact-container { margin: 0 20px; display: flex; justify-content: space-around;
            .item { display: inline-block; text-align: left; padding-right: 20px; position: relative; margin: 5px 0;
                .icon { position: absolute; left: 0; font-size: 28px; line-height: 30px; top: 2px; }
                .title { font-size: 14px; line-height: 16px; font-family: $bold; display: block; color: rgb(0, 0, 0); padding-left: 40px; }
                .subtitle { font-size: 14px; line-height: 16px; font-family: $regular; display: block; color: rgb(0, 0, 0); padding-left: 40px;}
            }
        }
    }
    .first { position: relative; display: flex; padding: 0 20px; height: 100px; transition: 0.2s height;
        .mobile-menu { display: none;}
        .card-container { display: flex ; width: 350px; margin: 10px 0;
            .logo-container { animation: spinOnHover infinite 20s linear;
                .logo { max-height: 80px; }
            }
            .card-literals { text-align: left; padding: 10px 10px 10px 20px;
                span { display: block; font-family: $regular; color: #fff;}
            }
        }
        .header-menu-container { margin-top: 35px; margin-left: auto; background-color: $bgColor; }
        .social-container {  margin-left: auto; animation: toVisible 1s ease-in-out ; 
            .openSocial { font-size: 18px; line-height: 20px; }
            ul { margin: 10px 0; height: 35px; margin-top: 32.5px;
                li { display: inline-block; margin-right: 15px; height: 100%;
                    a {  display: inline-block; width: 30px; height: 100%; line-height: 35px; 
                        &.phone {
                            .tel-text { margin: 0 10px; font-family: $bold; color: rgb(255, 255, 255); }
                        }
                    }
                    &:last-child { margin-right: 0; }
                    a svg { color: #fff; transition: 0.2s color; font-size: 30px; }
                    a:hover svg { color: black; }
                }
            }
        
        }
    }
    .third { position: relative; 
        
    }
    .second { position: relative; }
    
}
.header-sticky-container { top: -100%; position: fixed; transition: 0.4s ease-in-out top; width: 100%; background-color: $bgColor;
    .fluid {  display: flex; width: 100%; height: 60px;  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        .card-container { display: flex ; width: 350px; margin: 2.5px 0; padding: 0 10px;
            .logo-container { font-size: 0px;
                .logo { max-height: 55px; }
            }
            .card-literals { text-align: left; padding: 5px 20px 0px 20px; margin: 8px 0; 
                span { display: block; font-family: $regular; color: #fff; font-size: 12px; line-height: 14px}
            }
        }
        .header-menu-container { @include axis-x-Absolute; bottom: 0px; 
            .menu-container {
                li {
                    a { font-size: 14px ; line-height: 60px; }
                    &.active {
                        &::before { bottom: 15px; }
                    }
                } 
                .subcategories-container { @include axis-x-Absolute; display: none;  text-align: left; flex-direction: column; top: 34px; transition: 0.2s top; background-color: $bgColor; border: 2px solid $lightGreen; padding: 10px 30px; min-width: 150px; min-height: 100px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;
                    li a { line-height: 20px;} 
                    &:hover { display: flex;  }
                }
                .hasSubcategories {
                    &:hover { 
                       > a {  color: #fff; }
                        .subcategories-container { display: flex; top: 60px; }
                    }
                }
            }
        }
        .social-container {  margin-left: auto; animation: toVisible 1s ease-in-out ;
            ul { margin: 10px 0; height: 35px; margin-top: 32.5px;
                li { display: inline-block; margin-right: 15px; height: 100%;
                    a {  display: inline-block; width: 35px; height: 100%; line-height: 35px; 
                        &.phone {
                            .tel-text { margin: 0 10px; font-family: $bold; color: rgb(255, 255, 255); }
                        }
                    }
                    &:last-child { margin-right: 0; }
                    a svg { color: #fff; transition: 0.4s color; font-size: 35px; }
                    a:hover svg { color: black; }
                }
            }
        
        }
    }
}

@media only screen and (max-width: 1199px) {
    .isMobile {
        .header-container {  
            .zero { 
                .contact-container { margin: 0 10px; display: flex; justify-content: space-around;
                    .item {padding-right: 20px;
                        .icon { position: absolute; left: -6px; font-size: 22px; line-height: 25px; top: 2px; }
                        .title { font-size: 12px; line-height: 14px; padding-left: 20px; }
                        .subtitle { font-size: 12px; line-height: 14px; font-family: $regular; padding-left: 20px;}
                    }
                }
            }
            .first { position: relative; display: flex; padding: 0; height: 80px; background-color: $bgColor;
                
                .card-container { display: flex ; width: unset; margin: 10px 0;
                    .logo-container { 
                        .logo { max-height: 60px; }
                        &:hover { animation: spinOnHover infinite 20s linear; }
                    }
                    .card-literals { text-align: left; padding: 10px;
                        span { display: block; font-size: 14px; line-height: 20px; font-family: $regular; color: #fff;}
                    }
                }
                .header-menu-container { @include axis-x-Absolute; bottom: 10px; }
                .social-container {  margin-left: auto; animation: toVisible 1s ease-in-out ;
                    ul { height: 25px; margin-top: 27px;
                        li { display: inline-block; margin-right: 15px; height: 100%;
                            a {  display: inline-block; width: 25px; height: 100%; line-height: 25px; 
                                &.phone {
                                    .tel-text { margin: 0 10px; font-family: $bold; color: rgb(255, 255, 255); }
                                }
                            }
                            &:last-child { margin-right: 0; }
                            a svg { color: #fff; transition: 0.4s color; font-size: 25px; }
                            a:hover svg { color: black; }
                        }
                    }
                
                }
            }
            .third { position: relative; 
                
            }
            .second { position: relative; }
        }
        .header-sticky-container { display: none;}
    }
}

@media only screen and (max-width: 499px) {
    .isMobile {
        .header-container {  
            .zero { }
            .first { text-align: center;
                    .card-container { display: flex ; width: unset; margin: 10px 0;
                    .logo-container { 
                        .logo { max-height: 60px; }
                        &:hover { animation: spinOnHover infinite 20s linear; }
                    }
                    .card-literals { 
                        span { display: block; font-size: 13px; line-height: 20px; font-family: $regular; color: #fff;}
                    }
                }
                .header-menu-container { @include axis-x-Absolute; bottom: 10px; }
                .social-container {  display: none;  }
            }
            .third { position: relative; 
                
            }
            .second { position: relative; }
        }
    }
}
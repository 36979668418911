
.Therapeutical-container { margin: 0 16px;
    
    .main-description { font-size: var(--text); line-height: var(--ltext); text-align: left; display: flex; flex-direction: column;
        .main-image-container {  margin-bottom: 40px; text-align: center;
            img {   max-width: 1000px;}
        }
        h2 { font-size: var(--text); line-height: var(--ltext); margin-bottom:10px; }
        .linkedIn { color: #0077b5; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover { transform: scale(1.1); }
        }
        .facebook { color: #004970; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {  }
        }
        .instagram { color: #C13584; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {}
        }
        .description { margin-top: auto; margin-bottom: auto; }
    }

    .blockquote-container { padding-top: 50px; }

    .blockquote { font-weight: 100; font-size: 2rem; max-width: 800px; line-height: 1.4; position: relative; margin: 0 auto; padding: .5rem; }
    
    .blockquote:before, .blockquote:after { position: absolute; color: #f1efe6; font-size: 8rem; width: 4rem; height: 4rem; }
    
    .blockquote:before { content: '“'; left: -2rem; top: -2rem; }
    
    .blockquote:after { content: '”'; right: -2rem; bottom: 1rem; }
    
    .author { line-height: 3; text-align: left; }
    .boxes { }
}
.isMobile {
    .Therapeutical-container {
        .main-description {
            .main-image-container {
                img { max-width: 100%; }
            }
        }
    }
}
@import '../../global/styling/generic.scss';
.scrollToForm { position: fixed; z-index: 5; cursor: pointer; bottom: 100px; right: 25px; width: 140px; height: 120px;  border-radius: 20px; display: flex; animation: toVisible 0.4s ease-in-out; flex-direction: column; background-color: $bgColor; padding: 25px 0; box-sizing: border-box;
    svg { font-size: 25px; color: $lightBlue; }
    .underline { height: 2px; background-color: #bdab6f; width: 0; transition: 0.4s ease-in-out ; margin: 0 auto; }
    .book { font-size: 16px; color: #fff; margin-top: 10px; position: relative; }
    &:hover {
        .underline { width: 90%; }
    }
}
.footer-container { z-index: 10; position: relative; overflow-x: hidden;
    .zero { position: relative; background-color: #0E4247; 
        .contact-form-container { padding: 40px 0; display: flex;
            .form-container { margin-right: 20px; }
            .map-container { animation: toVisible 1s ease-in-out ;  margin-left: auto; 
                .map { 
                    img { width: 500px; height: 300px; margin-left: auto; margin-bottom: 10px; }
                }
                .address-container { text-align: left; font-size: 20px; line-height: 24px; margin-top: var(--small);
                    .address { display: block; margin-bottom: 10px;
                        .label { display: inline-block; font-size: var(--stext); line-height: var(--lstext); font-weight: 700; color: #bdab6f; }
                        .text { display: inline-block;  margin-left: 5px; font-size: var(--stext); line-height: var(--lstext); color: #fff; }
                    }
                    .tel { display: block; margin-bottom: 10px;
                        .label { display: inline-block; font-size: var(--stext); line-height: var(--lstext); font-weight: 700; color: #bdab6f; }
                        .text { display: inline-block; margin-left: 5px; font-size: var(--stext); line-height: var(--lstext); color: #fff; }
                        a { color: #fff; }
                    }
                    .email { display: block; margin-bottom: 40px;
                        .label { display: inline-block; font-size: var(--stext); line-height: var(--lstext); font-weight: 700; color: #bdab6f; }
                        .text { display: inline-block; margin-left: 5px; font-size: var(--stext); line-height: var(--lstext); color: #fff;}
                        a { color: #fff; }
                    }
                    .social-container {
                        .title { font-size: var(--stext); line-height: var(--lstext); font-weight: 700; text-align: center;

                        }
                    }
                    ul { margin-top: 20px; text-align: center;
                        li { display: inline-block; margin-right: 15px; height: 100%;
                            a {  display: inline-block; width: 30px; height: 100%; line-height: 35px; 
                                &.phone {
                                    .tel-text { margin: 0 10px; font-family: $bold; color: rgb(255, 255, 255); }
                                }
                            }
                            &:last-child { margin-right: 0; }
                            a svg { color: #fff; transition: 0.2s color; font-size: 30px; }
                            a:hover svg { color: black; }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1550px) {
    .footer-container { 
        .zero { 
            .contact-form-container { display: flex; flex-direction: column;
                .map-container {  margin: 0 auto; 
                    .leaflet-container { width: 100%; height: 300px; }
                }
            }
        }
    }
}
@import '../../global/styling/generic.scss';

.form-container { position: relative; padding: 10px 0; margin: 0 auto; background-color: transparent; border-radius: 15px; position: relative; overflow: hidden; width: calc(100% - 420px );
    .title { font-size: 22px; line-height: 24px; color: #fff; }
    .input { position: relative; display: inline-block;  width: calc(50% - 30px); max-width: 400px;
        &:nth-child(even) { margin-right: 30px; }
        input { display: inline-block; height: 55px;  width: 100%; margin: 0 auto; border: none; background-color: transparent; position: relative; font-size: 18px;
            +label{ -webkit-transform:translateY(0px); transform:translateY(0px); -webkit-transition:.5s; transition:.5s; padding: 0 10px; color: #fff; font-size: 20px; position: absolute; left: -10px; border: none; top: 5px; }
            &:hover, &:focus, &:active:focus, &:focus-visible , &:active, &:not(:placeholder-shown) { color: $bgColor;  border: none; outline: none; color: #fff;
                +label{ color: #fff;  -webkit-transform:translateY(-15px); transform:translateY(-15px); border: none; outline: none; letter-spacing: 0.1em; font-weight: 700; font-size: 16px; }
            }
        }
        &:before { content: ''; width: 100%; height: 1px; background-color: white; position: absolute; bottom: 0;}
    }
    #popup { @include axis-x-Absolute; padding: 20px; border-radius: 20px; background-color: white; border: 1px solid $lightGreen1; font-size: 16px; line-height: 18px; color: black; 
        .title { color: black; font-size: 18px; line-height: 20px; }
        &.hidden { display: none;}
    }
    .textarea { position: relative; display: inline-block;  width: 100%; max-width: 830px; margin-right: auto;
        textarea { display: inline-block; height: 55px;  width: 100%; margin: 0 auto; border: none; background-color: transparent; position: relative; font-size: 18px; resize: none;
            +label{ -webkit-transform:translateY(0px); transform:translateY(0px); -webkit-transition:.5s; transition:.5s; padding: 0 10px; color: #fff; font-size: 20px; position: absolute; left: -10px; border: none; top: 5px; }
            &:hover, &:focus, &:active:focus, &:focus-visible , &:active, &:not(:placeholder-shown) { color: $bgColor;  border: none; outline: none; color: #fff;
                +label{ color: #fff;  -webkit-transform:translateY(-25px); transform:translateY(-25px); border: none; outline: none; letter-spacing: 0.1em; font-weight: 700; font-size: 16px; }
            }
        }
        &:before { content: ''; width: 100%; height: 1px; background-color: white; position: absolute; bottom: 0; }
    }
    form { padding-bottom: 50px; padding-top: 16px; overflow: hidden; position: relative;}
    .button { @include axis-x-Absolute; bottom: 20px; border-radius: 10px;  cursor: pointer;  font-size: 18px; line-height: 60px; width: 200px; height: 60px; background-color: $lightGreen1;  border: none; color: #fff;   transition: all 0.4s ease-in-out;
        svg { @include axis-y-Absolute; right: 45px; transition: all 0.4s ease-in-out;}
        .text { opacity: 0; @include allAbsolute; color: #fff; transition: .4s opacity; transition-delay: 0.4s; }
        &:hover { background-color: $lightGreen;
            svg { animation:  forwards rotate-the-funk 0.1s linear; }
        }
        &.eatTheBox { animation: eatTheBox forwards ease-in-out 0.4s; pointer-events: none;
            .mainText { display: none;}
            &:hover { background-color: $lightGreen;
                svg { animation:  none; }
            }
            svg { display: none; }
            .text { opacity: 1; display: block; }
        }
    }
}
@keyframes rotate-the-funk {
    0% { transform: translate( 0px, -10px );}
    100% {transform: translate( 5px, -10px );}
}


@keyframes eatTheBox {
    0% { width: 200px; height: 60px; }
    100% { width: 100%; height: 100%; bottom: 0; }
}

@media only screen and (max-width: 1550px) {
    .form-container {  width: 100%; padding: 10px; box-sizing: border-box;
        .input { position: relative; display: inline-block;  width: calc(50% - 20px);
            &:nth-child(even) { margin-right: 20px; }
        }
        .textarea { max-width: 820px;
        }
    }
}
@media only screen and (max-width: 480px) {
    .form-container {  width: 100%; padding: 10px; box-sizing: border-box;
        form { padding-bottom: 100px; }
        .input { position: relative; display: inline-block;  width: 100%; margin-bottom: 20px;
            &:nth-child(even) { margin-right: 0; }
        }
        br { display: none;}
        .textarea { max-width: 820px;
        }
    }
}
@import '../../global/styling/generic.scss';
.main-servicesList-container { padding-top: var(--medium);
    .title-container { padding-bottom: 10px; font-weight: 800;
        .title { font-size: var(--text); line-height: var(--ltext);}
    }
}
.servicesList-container { display: grid; grid-auto-columns: 1fr; grid-auto-flow: column; column-gap: 10px;
    
    .servicesList-box { position:  relative; margin-bottom: var(--big);
        .image-container { display: flex; overflow: hidden; border-radius: 10px;
            img { width: 100%; height: auto; aspect-ratio: 1.333; transition: 0.4s transform; }
        }
        .text-container { 
            .text { text-align: left; font-size: var(--stext); line-height: var(--lstext); margin-top: 10px; display: inline-block; position: relative;
                &:before { content: ''; height: 2px; width: 0; background-color: #bdab6f; position: absolute; bottom: -5px; transition: 0.4s width; }
            }
        } 
        &:hover {
            .image-container { 
                img { transform: scale(1.1); transform-origin: 50% 50%; }
            }
            .text { 
                &:before { width: 100%;  }
            }
        }
    }
}
.isMobile {
    .servicesList-container { display: grid;  grid-template-rows: auto auto; column-gap: 5px;
        .servicesList-box { margin-bottom: var(--small); overflow: hidden; }
    }
}
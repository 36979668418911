
.services-container { 
    
    .main-description { margin: 0 16px; font-size: var(--text); line-height: var(--ltext); text-align: left; display: flex; flex-direction: column;
        > .image-container { margin-right: 40px; 
            > img { width: 250px; }
        }
        .main-image-container { margin-bottom: 20px; text-align: center;}
        h2 { font-size: var(--text); line-height: var(--ltext); margin-bottom:10px; }
        .linkedIn { color: #0077b5; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover { transform: scale(1.1); }
        }
        .facebook { color: #004970; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {  }
        }
        .instagram { color: #C13584; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {}
        }
        .description { margin-top: auto; margin-bottom: auto; }
    }

    .services-container { padding: var(--medium) 0 var(--big); 
        .container {
            .title { text-align: center; font-size: var(--textm); line-height: var(--textm); font-weight: 400; text-decoration: underline; }
            .services-list { display: flex; justify-content: space-between; padding-top: var(--medium);
                .service { width: 33%; 
                    a { 
                        .image-container { width: 100%; text-align: center;
                            img { height: 170px; }
                        }
                        .description { padding-top: var(--medium); text-align: center; margin: 0 auto;
                            .title { font-size: var(--stext); line-height: var(--lstext); text-decoration: unset; font-weight: 600; position: relative; display: inline-block;color: #000;
                                &:before { position: absolute; bottom: -4px; width: 0; height: 2px; background-color: #4bab9e; content: ""; transition: width 0.4s ease-in-out; }
                            }
                        }
                        &:hover {
                            .description {
                                .title { &:before { width: 100%; } }
                            }
                        }
                    }
                    
                }
            }
            @media screen and (max-width: 768px) { 
                .services-list { flex-wrap: wrap;
                    .service { width: 100%; padding-bottom: var(--medium);
                        a { 
                            .image-container { width: 100%; text-align: center;
                                img { height: 170px; }
                            }
                            .description { padding-top: var(--medium); text-align: center; margin: 0 auto;
                                .title { font-size: var(--stext); line-height: var(--lstext); text-decoration: unset; font-weight: 600; position: relative; display: inline-block;color: #000;
                                    &:before { position: absolute; bottom: -4px; width: 0; height: 2px; background-color: #4bab9e; content: ""; transition: width 0.4s ease-in-out; }
                                }
                            }
                            &:hover {
                                .description {
                                    .title { &:before { width: 100%; } }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .online-container { background-color: #eef0f0;
        .container { display: flex; position: relative; min-height: 200px; align-content: center; justify-content: center; flex-wrap: wrap;
            .image-container { position: absolute; left: 0; top: 50%; transform: translateY(-50%);
                img { max-height: 150px; }
            }
            .desc-container { display: flex; flex-direction: column; align-items: center; justify-content: center;
                .description { padding-bottom: var(--small); text-align: center; font-size: var(--stext); line-height: var(--ltext); font-weight: 600; }
                .button-container {  display: flex;
                    a {
                        &:first-child { margin-right: 8px; }
                    }
                }
            }
            @media screen and (max-width: 1580px) {
                .image-container { left: 16px; }
            }
            @media screen and (max-width: 768px) {
                flex-direction: column; padding: var(--medium) 0;
                .image-container { position: unset; transform: unset; margin: 0 auto;
                    img { max-height: 150px; }
                }
                .desc-container { padding-top: var(--small); }
            }
            @media screen and (max-width: 480px) {
                .desc-container {
                    .button-container { flex-direction: column;
                        a {
                            &:first-child { margin-right: 0; margin-bottom: 8px; }
                        }
                    } 
                }
            }
        }
    }
}
.isMobile {
    .services-container {
        .main-image-container {
            img { max-width: 100%; }
        }
    }
}
@import '../../global/styling/generic.scss';

.menu-container { position: relative ; text-align: center; padding: 0 20px; position: relative;
    li {  display: inline-block; margin-right: 15px; animation: toVisible 0.3s ease-in-out ; position: relative;
        &:last-child { margin-right: 0; }
        > a { font-size: 16px; line-height: 18px; text-transform: uppercase; padding: 43px 10px; transition: 0.2s color; color: #fff; letter-spacing: 0.6px;
            &:hover { color: $lightBlue; }
            svg { font-size: 13px; }
        }
        &:before { content: ''; width: 0; bottom: -10px; height: 2px; background-color: #bdab6f; transition: width 0.4s ease-in-out; position: absolute; }
        &.active {
            &::before { width: 100%; }
        }
    }
    .subcategories-container { @include axis-x-Absolute; display: none;  text-align: left; flex-direction: column; top: 60px; transition: 0.6s top; background-color: $bgColor; border: 2px solid $lightGreen; padding: 10px 30px; min-width: 150px; min-height: 50px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; 
        &:hover { display: flex;  }
    }
    .hasSubcategories {
        &:hover { 
           > a {  color: #fff; }
            .subcategories-container { display: flex; top: 60px; }
        }
        li { margin-bottom: 10px; overflow: hidden; 
            a { float: left; font-size: 14px; line-height: 16px; padding: 0; animation: slideInFromLeft  0.6s ease-in-out; white-space: nowrap;}
        } 
    }
    .mobile-social-container { display: none;}
        
}
.isMobile {
    .mobile-menu {
        .menu-container { position: fixed; opacity: 1; top: 80px; padding: 20px; height: calc( 100vh - 80px); width: 100vw; display: flex; left:0; transition: 0.6s left; overflow-y: auto; background-color: $bgColor; flex-direction: column; z-index: 100; border-bottom-right-radius: 5px;
            li { text-align: left; font-size: 20px; line-height: 22px; width: fit-content; margin-bottom: 10px; 

                > a { padding: 0; position: relative; line-height: 150%;
                    &:before { content: ''; width: 0; bottom: -2px; height: 2px; background-color: #bdab6f; transition: width 0.4s ease-in-out; position: absolute; }
                }
                
                &.active {
                    &:before { display: none; }
                    > a { padding: 0; 
                        &::before { width: 100%; }
                    }
                }
            }
            
            .subcategories-container {
                &:hover { display: none ;}
                li {
                    &:last-child { margin-bottom: 0; }
                }
            }
            .hasSubcategories {
                &:hover { 
                    > a {  color: #fff; }
                    
                }
                .subcategories-container { @include unset-axis-x-Absolute; top: 0; position: relative; display: flex; border: none; min-height: unset; min-width: unset; }
            }
            @media screen and (max-width: 499px) {
                .mobile-social-container { margin-top: 10px; display: block;
                    li {
                        a { font-size: 20px; line-height: 22px;
                            svg { font-size: 25px; }
                        }
                    }
                }
            }
        }
    }
}
@import '../../global/styling/generic.scss';
.mySwiperEffectCards{
    &.swiper {
        width: 750px;
        height: 500px;
    }
    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
    }

    .swiper-slide { display: flex; position: relative;
        .image-container { width: 100%; height: 100%;
            img { border-radius: 10px; max-width: 100%; max-height: 100%; }
        }
        .desc-container { @include allAbsolute; width: 100%; text-align: center; overflow: auto;
            .text { font-size: var(--textm); line-height: var(--ltextm); color: white;  width: calc(100% - 60px); margin: 0 auto; margin-bottom: 10px; }
            .smallText { font-size: var(--texts); line-height: var(--ltexts); color: white; width: calc(100% - 60px); margin: 0 auto; letter-spacing: 0.8px; }
            .text, .smallText {
                &.green { color: $bgColor; }
                &.white { color: #fff; }
            }
        }
    }
}

.mySwiperCube{
    &.swiper {
        width: 700px;
        height: 700px;
    }
    .swiper-pagination-bullet { background: #fff; }
    .swiper-pagination-bullet-active { background: $bgColor; }
    .swiper-slide { display: flex; position: relative;
        .image-container { width: 100%; height: 100%;
            img {  max-width: 100%; max-height: 100%; border-radius: 0; }
        }
        .desc-container { @include allAbsolute;
            .text { font-size: var(--textm); line-height: var(--ltextm); color: #fff; }
        }
    }
}
@media only screen and (max-width: 1050px) {
    .mySwiperEffectCards{
        &.swiper {
            width: 450px;
            height: 300px;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
        }
    
        .swiper-slide { display: flex; position: relative;
            .image-container { width: 100%; height: 100%;
                img { border-radius: 10px; max-width: 100%; max-height: 100%; }
            }
            .desc-container { @include allAbsolute; width: 100%; text-align: center; 
                .text { font-size: 16px; line-height: 18px; color: white;   width: calc(100% - 20px);}
                .smallText { font-size: 14px; line-height: 18px; color: white; width: calc(100% - 20px);}
                .text, .smallText {
                    &.green { color: $bgColor; }
                    &.white { color: #fff; }
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .mySwiperEffectCards{
        &.swiper {
            width: 320px;
            height: 213.33px;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
        }
    
        .swiper-slide { display: flex; position: relative;
            .image-container { width: 100%; height: 100%;
                img { border-radius: 10px; max-width: 100%; max-height: 100%; }
            }
            .desc-container { @include allAbsolute; width: 100%; text-align: center; overflow: auto;
                .text { font-size: 12px; line-height: 14px; color: white;  width: calc(100% - 10px); }
                .smallText { font-size: 11px; line-height: 11px; color: white; overflow: hidden; width: calc(100% - 10px); }
                .text, .smallText {
                    &.green { color: $bgColor; }
                    &.white { color: #fff; }
                }
            }
        }
    }
}
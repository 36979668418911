@import '../../global/styling/generic.scss';

.qna-container { 
    .main-title { font-size: var(--title); line-height: var(--ltitle); font-weight: 600; }
    .qnaList-container { display: flex; flex-direction: column; padding-top: var(--small);
        .qna-box{ display: flex; position: relative; width: 100%; border-bottom: 1px solid $bgColor; 
            .box-container { 
                .text-container { transition: max-height 0.4s ease-in-out; max-height: 0;
                    .text { padding: 20px 0;  opacity: 0; transition: opacity 0.6s ease-in-out; }
                }
                .title-container {
                    .title { cursor: pointer; font-weight: 600;
                        &:before { content: "+"; position: absolute; right: 10px; color: $bgColor; font-size: 32px; transition: transform 0.4s ease-in-out ; }
                    }
                }
            }
            &.closed { 
                .box-container {
                    .text-container { max-height: 0;  
                    }
                }
                .title-container {
                    .title {
                        &:before { transform: rotate(0); }
                    }
                }
            }
            &.opened {
                .box-container { 
                    .text-container { max-height: 500px;  
                        .text { opacity: 1;  }
                    }
                    
                }
                .title-container { 
                    .title {
                        &:before { transform: rotate(-45deg); }
                    }
                }
            }
            .box-container { width: calc(100% - 36px);
                .text {  overflow: hidden; font-size: var(--text); line-height: var(--ltext); }
            }
            .title-container { padding: 20px 0; 
                .title { font-size: var(--text); line-height: 130%; }
            }
        }
    }
}
$bgColor: #0E4247;
$lightBlue: #F4ECDA;
$lightGreen: #38A687;
$green: #2C735F;
$lightGreen1: #65BFA6;
$opposite: #471d0e;
$regular: 'OpenSansRegular';
$bold: 'OpenSansBold';
$medium: 'OpenSansMedium';

﻿/* Reset */
a, abbr, acronym, address, applet, article, aside, audio,
b, blockquote, big, body,
center, canvas, caption, cite, code, command,
datalist, dd, del, details, dfn, dl, div, dt, 
em, embed,
fieldset, figcaption, figure, font, footer, form, 
h1, h2, h3, h4, h5, h6, header, hgroup, html,
i, iframe, img, ins,
kbd, 
keygen,
label, legend, li, 
meter,
nav,
object, ol, output,
p, pre, progress,
q, 
s, samp, small, span, source, strike, strong, sub, sup,
table, tbody, tfoot, thead, th, tr, tdvideo, tt,
u, ul, 
var{
    // background: transparent;
    border: 0 none;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline; 
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
section { overflow: hidden;}
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
body { font-family: $regular; }

ul > li { list-style: none; display: block; margin: 0; font-size: 0; }
a, a:visited { outline: none; text-decoration: none; cursor: pointer; }

//Variables
.no-scroll { overflow-y: hidden; }
:root {
    --big: 128px;
    --medium: 64px;
    --medium: 64px;
    --small: 32px;
    --vsmall: 16px;
    //font sizes
    --btitle: 64px;
    --lbtitle: 66px; 

    --title: 48px;
    --ltitle: 50px;

    --textm: 32px;
    --ltextm: 36px;

    --text: 24px;
    --ltext: 34px;

    --stext: 16px;
    --lstext: 20px;
}
@media only screen and (max-width: 1499px) {
  .center-container { width: 100%; }
}

@media only screen and (max-width: 1199px) {
  :root {
    --big: 96px;
    --medium: 56px;
    --small: 24px;
    --vsmall: 16px;

    //font sizes
    --title: 42px;
    --ltitle: 44px;

    --textm: 32px;
    --ltextm: 34px;

    --text: 24px;
    --ltext: 28px;

    --stext: 18px;
    --lstext: 20px;
  }
}

@media only screen and (max-width: 768px) { 
    :root {
      --big: 64px;
      --medium: 56px;
      --small: 16px;
      --vsmall: 8px;

      //font sizes
      --title: 32px;
      --ltitle: 34px;

      --textm: 26px;
      --ltextm: 28px;

      --text: 20px;
      --ltext: 24px;

      --stext: 16px;
    --lstext: 18px;
    }
}
@media only screen and (max-width: 479px) { 
  :root { 
    --big: 56px;
    --medium: 32px;
    --small: 8px;
    //font sizes
    --title: 24px;
    --ltitle: 26px;

    --textm: 24px;
    --ltextm: 26px;

    --text: 16px;
    --ltext: 20px;

    --stext: 16px;
    --lstext: 18px;
  }
}
//Mixins

@mixin axis-x-Absolute { position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%) }
@mixin unset-axis-x-Absolute { position: relative; left: unset; -webkit-transform: unset; transform: unset; }
@mixin axis-y-Absolute { position: absolute;  -webkit-transform: translateY(-50%); top: 50%; transform: translateY(-50%); }
@mixin allAbsolute { position: absolute;  -webkit-transform: translate(-50%, -50%); top: 50%; left: 50%; transform: translate(-50%, -50%);  }


//Generic config for css
.center-container { width: 1500px; margin: 0 auto; }
.inner-container { width: 1200px; margin: 0 auto; }
.box { background-color: #fff; border-radius: 10px; }
.hidden { display: none; } 
img { border-radius: 10px; }
.btn { transition: 0.8s; position: relative; background-color: #837148; color: #fff; font-weight: 700; overflow: hidden; border-radius: 40px; padding: 10px 20px; 
  &:disabled { cursor:not-allowed; pointer-events: none; opacity: 0.5; }
  &:hover { background-color: #9b8a65;}
  &.green { background-color: #5cc2a8; color: #fff; font-size: var(--text); line-height: var(--ltext); font-weight: 400; padding: 5px 10px; border-radius: 8px;
    &:hover { background-color: #8fe0cc; }
  }
}
.headerIsScrolled {
  .scrollToTop { display: block; animation: slideInFromRight 0.6s ease-in-out; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
}
.scrollToTop { position: fixed; width: 50px; height: 50px; opacity: .7; border-radius: 50%; bottom: 20px; right: 20px; background-color: #fff; cursor: pointer; transition: 0.6s ease-in-out opacity; display: none;
  svg { @include allAbsolute(); transition: 0.6s ease-in-out top;}
  &:hover { opacity: 1; 
    svg { top: 40%; }
  }
}
@media only screen and (max-width: 1580px) {
  .center-container { width: 100%; padding: 0 16px; box-sizing: border-box; }
}




//Animations
.animate-on-scroll {
	opacity: 0;
	transform: translate(0, 50px);
	transition: all 0.6s ease-out;
	transition-delay: 0.6s;
}
.animate-on-scroll-right {
	opacity: 0;
	transform: translate(300px, 0);
	transition: all 0.6s ease-out;
	transition-delay: 0.6s;
}
.animate-top-down {
	opacity: 0;
	transform: translate(0, -300px);
	transition: all 0.6s ease-out;
	transition-delay: 0.6s;
}
.animate-down-top {
	opacity: 0;
	transform: translate(0, 300px);
	transition: all 0.6s ease-out;
	transition-delay: 0.6s;
}

.animate {
	opacity: 1;
	transform: translate(0, 0);
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromRight{
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideOutToTop{
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes slideInFromLeft{
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes letterSpacing{
  0% {
    letter-spacing: -30px;
  }
  100% {
    letter-spacing: 2px;
  }
}
@keyframes toVisible{
  0% {
    display: none;
    opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}
@keyframes LargeToSmall{
  0% {
    width: 150%;
  }

  100% {
      width: 100%;
  }
}
  @keyframes spinOnHover {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
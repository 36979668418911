.CV-container { margin: 0 16px;
    
    .main-description { font-size: var(--text); line-height: var(--ltext); text-align: left; display: flex; flex-direction: column;
        .image-container { width: 100%; max-width: 700px; margin: 0 auto; margin-bottom: 40px;
            img { width: 100%; }
        }
        .linkedIn { color: #0077b5; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover { transform: scale(1.1); }
        }
        .facebook { color: #004970; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {  }
        }
        .instagram { color: #C13584; transition: 0.4s border-bottom ease-in-out; border-bottom: 1px solid transparent; line-height: 110%;
            &:hover {}
        }
        .description { margin-top: auto; margin-bottom: auto; }
    }
    .main-gallery {
        .title { font-size: var(--title); line-height: var(--ltitle); text-align: left; margin-bottom: var(--small);}
    }

    .blockquote-container { padding-top: 50px; }

    .blockquote { font-weight: 100; font-size: 2rem; max-width: 800px; line-height: 1.4; position: relative; margin: 0 auto; padding: .5rem; }
    
    .blockquote:before, .blockquote:after { position: absolute; color: #f1efe6; font-size: 8rem; width: 4rem; height: 4rem; }
    
    .blockquote:before { content: '“'; left: -2rem; top: -2rem; }
    
    .blockquote:after { content: '”'; right: -2rem; bottom: 1rem; }
    
    .author { line-height: 3; text-align: left; }
    .boxes { }
}